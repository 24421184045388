/* Copyright 2013 - 2022 Waiterio LLC */
import React from 'react'

const IllustrationLogin = props => {
  let {
    className,
    width,
    height,
    onClick,
    style,
    primaryColor = 'var(--color-primary)',
    secondaryColor = 'var(--color-secondary)',
  } = props
  const dataTestId =
    props['data-testid'] || 'illustration-man-working-on-computer'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 793 551.732"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <ellipse cx="158" cy="539.732" fill={secondaryColor} rx="158" ry="12" />
      <path
        fill="#2f2e41"
        d="M120.772 122.42c27.497-11.696 61.745-4.286 95.191.857.311-6.228 4.084-13.808.132-18.153-4.801-5.279-4.359-10.825-1.47-16.403 7.388-14.265-3.197-29.444-13.884-42.065a23.67 23.67 0 0 0-19.756-8.292l-19.797 1.414a23.71 23.71 0 0 0-21.053 16.946c-4.727 6.43-7.257 12.84-5.664 19.219-7.081 4.839-8.27 10.68-5.089 17.264 2.698 4.146 2.67 8.182-.123 12.106a55.89 55.89 0 0 0-8.31 16.506ZM774.209 476.963H213.79A18.791 18.791 0 0 1 195 458.172q304.727-35.415 598 0a18.791 18.791 0 0 1-18.791 18.791Z"
      />
      <path
        fill="#3f3d56"
        d="m793 459.277-598-1.105 69.306-116.616.332-.552V83.996a23.752 23.752 0 0 1 23.754-23.754h407.9a23.752 23.752 0 0 1 23.754 23.754v258.776Z"
      />
      <path
        fill="#fff"
        d="M287.85 76.823a7.746 7.746 0 0 0-7.737 7.737v234.336a7.747 7.747 0 0 0 7.737 7.738h412.3a7.747 7.747 0 0 0 7.737-7.738V84.56a7.747 7.747 0 0 0-7.737-7.737Z"
      />
      <path
        fill="#2f2e41"
        d="M289.578 357.584a3.325 3.325 0 0 0-3.013 1.93L265.21 405.94a3.316 3.316 0 0 0 3.012 4.702h449.092a3.316 3.316 0 0 0 2.965-4.799l-23.212-46.425a3.3 3.3 0 0 0-2.965-1.833Z"
      />
      <circle cx="492.342" cy="67.98" r="4.974" fill="#fff" />
      <path
        fill="#2f2e41"
        d="M448.2 419.484a3.321 3.321 0 0 0-3.202 2.454l-5.357 19.896a3.316 3.316 0 0 0 3.202 4.179h101.874a3.315 3.315 0 0 0 3.133-4.401l-6.887-19.896a3.318 3.318 0 0 0-3.134-2.232ZM720.046 337.135v4.421h-455.74l.343-.552v-3.869h455.397z"
      />
      <circle cx="707.335" cy="77.375" r="77.375" fill={primaryColor} />
      <path
        fill="#fff"
        d="M739.39 111.089h-64.11a4.426 4.426 0 0 1-4.422-4.422V67.98a4.426 4.426 0 0 1 4.421-4.422h64.111a4.426 4.426 0 0 1 4.421 4.422v38.687a4.426 4.426 0 0 1-4.421 4.422Zm-64.11-43.11v38.688h64.113l-.003-38.687Z"
      />
      <path
        fill="#fff"
        d="M727.231 67.98h-39.793V50.294c0-12.81 8.368-22.107 19.897-22.107s19.896 9.297 19.896 22.107Zm-35.371-4.422h30.95V50.294c0-10.413-6.364-17.686-15.475-17.686s-15.475 7.273-15.475 17.686Z"
      />
      <circle cx="707.335" cy="86.218" r="4.421" fill="#fff" />
      <path
        fill={secondaryColor}
        d="M653.32 247.15H334.68a5.908 5.908 0 0 1-5.9-5.901v-79.041a5.908 5.908 0 0 1 5.9-5.901h318.64a5.908 5.908 0 0 1 5.9 5.9v79.042a5.908 5.908 0 0 1-5.9 5.9Zm-318.64-88.483a3.544 3.544 0 0 0-3.54 3.54v79.042a3.544 3.544 0 0 0 3.54 3.54h318.64a3.544 3.544 0 0 0 3.54-3.54v-79.041a3.544 3.544 0 0 0-3.54-3.54Z"
      />
      <circle cx="384.19" cy="198.695" r="24.036" fill={secondaryColor} />
      <path
        fill={secondaryColor}
        d="M439.703 182.671a4.006 4.006 0 1 0 0 8.012h188.858a4.006 4.006 0 0 0 0-8.012ZM439.703 206.708a4.006 4.006 0 1 0 0 8.012h81.266a4.006 4.006 0 1 0 0-8.012Z"
      />
      <path
        fill="#ffb8b8"
        d="m263.522 208.328-58.903 31.316-.746-26.097c19.226-3.209 37.517-8.797 54.43-17.894l6.16-15.22A10.318 10.318 0 0 1 282 177.755a10.318 10.318 0 0 1-.909 14.069Z"
      />
      <path
        fill="#2f2e41"
        d="M119.598 389.133a11.574 11.574 0 0 1 1.47-9.363l12.939-19.858a22.612 22.612 0 0 1 29.335-7.74c-5.438 9.257-4.68 17.377 1.878 24.434a117.63 117.63 0 0 0-27.936 19.045 11.574 11.574 0 0 1-17.686-6.518Z"
      />
      <path
        fill="#2f2e41"
        d="M266.205 363.168a22.203 22.203 0 0 1-18.871 10.78l-85.96.65-3.728-21.622 38.026-11.184-32.062-24.605 35.044-41.008 63.65 59.324a22.203 22.203 0 0 1 3.9 27.666Z"
      />
      <path
        fill="#2f2e41"
        d="M147.953 511.045H127.82c-18.075-123.898-36.474-248.142 17.895-294.515l64.122 10.438-8.202 54.43-35.789 41.008Z"
      />
      <path
        fill="#2f2e41"
        d="M165.65 539.112a11.574 11.574 0 0 1-9.364-1.469l-21.859-2.938a22.612 22.612 0 0 1-7.74-29.335c9.256 5.438 17.377 4.68 24.433-1.88 4.986 10.068 13.2 9.454 21.047 17.936a11.574 11.574 0 0 1-6.518 17.686Z"
      />
      <path
        fill="#ffb8b8"
        d="m195.672 133.767-37.28-8.947c6.19-12.674 6.7-26.776 3.727-41.754l25.35-.746c.795 18.626 3.198 36.027 8.203 51.447Z"
      />
      <path
        fill={primaryColor}
        d="M205.918 249.418c-27.14 18.493-46.315.633-60.948-26.923 2.034-16.862-1.259-37.04-7.356-58.967a40.138 40.138 0 0 1 24.505-48.4l32.061 13.42c27.224 22.19 32.582 46.227 22.369 71.579Z"
      />
      <path
        fill="#ffb8b8"
        d="m127.821 152.408-29.824 15.657 52.938 31.316 7.366 18.17a9.637 9.637 0 0 1-5.79 12.73 9.637 9.637 0 0 1-12.76-8.544l-.746-12.663-67.283-22.204a15.733 15.733 0 0 1-9.873-9.611 15.733 15.733 0 0 1 5.903-18.303l54.104-37.118Z"
      />
      <path
        fill={primaryColor}
        d="M157.646 155.39c-12.439-5.451-23.75.47-38.026 5.22l-2.237-39.518c14.176-7.556 27.692-9.593 40.263-3.728Z"
      />
      <circle cx="172.525" cy="78.093" r="23.802" fill="#ffb8b8" />
      <path
        fill="#2f2e41"
        d="M201 75.09c-23.566 2.307-41.523-1.547-53-12.52v-8.838h51Z"
      />
    </svg>
  )
}

export default IllustrationLogin
