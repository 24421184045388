/* Copyright 2013 - 2022 Waiterio LLC */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'

import { useTranslation } from '@multilocale/react/index.js'

import NavBar from './NavBar.js'
import NavBarFiller from './NavBarFiller.js'
import NavBarSmartphoneMenu from './NavBarSmartphoneMenu.js'
import NavBarSmartphoneButton from './NavBarSmartphoneButton.js'
import NavBarSmartphoneLink from './NavBarSmartphoneLink.js'

const navbarSmartphoneLogo = css`
  margin-left: 16px;
  -webkit-tap-highlight-color: transparent;
`

const NavigationSmartphoneComponent = ({
  locale,
  AppIcon,
  appName,
  appUrl,
}) => {
  const { t } = useTranslation(locale)

  return (
    <NavBar>
      <a aria-label={appName} css={navbarSmartphoneLogo} href="/">
        {AppIcon}
      </a>
      <NavBarFiller />
      <NavBarSmartphoneMenu>
        <NavBarSmartphoneLink label={t('Login')} href={`${appUrl}/login`} />
        <NavBarSmartphoneLink label={t('Blog')} href="/blog" />
        <NavBarSmartphoneButton
          label={t('Try it for free')}
          href={`${appUrl}/signup`}
        />
      </NavBarSmartphoneMenu>
    </NavBar>
  )
}

export default NavigationSmartphoneComponent
