/* Copyright 2013 - 2022 Waiterio LLC */
import React from 'react'

const IconCart = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-cart'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 12 12"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="m11.5 8 .5-5-9.8196411-.9064331L1.9477539 0H0v1h1.0522461l.9008179 8.1067505C1.3963623 9.3261108 1 9.866272 1 10.5c0 .8271484.6728516 1.5 1.5 1.5S4 11.3271484 4 10.5c0-.1761475-.0360718-.3428345-.0921021-.5H8.092102C8.0360718 10.1571655 8 10.3238525 8 10.5c0 .8271484.6728516 1.5 1.5 1.5s1.5-.6728516 1.5-1.5S10.3271484 9 9.5 9H2.9476318l-.111145-1H11.5zm-9 3c-.2758789 0-.5-.2241211-.5-.5 0-.2634277.2062378-.473938.4649048-.4929199l.0452881-.0050049C2.7811279 10.0078125 3 10.2277222 3 10.5c0 .2758789-.2241211.5-.5.5zm7.5-.5c0 .2758789-.2241211.5-.5.5s-.5-.2241211-.5-.5.2241211-.5.5-.5.5.2241211.5.5z" />
    </svg>
  )
}

export default IconCart
