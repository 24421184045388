/* Copyright 2013 - 2022 Waiterio LLC */
import { css } from '@emotion/react'

export default css([
  {
    // to avoid Safari bug https://stackoverflow.com/questions/10819565/user-selectnone-causes-input-field-to-be-inaccessible-on-safari
    '*:not(input, textarea)': {
      WebkitTouchCtextout: 'none',
      WebkitUserSelect: 'none',
      KhtmlUserSelect: 'none',
      MozUserSelect: 'none',
      MsUserSelect: 'none',
      userSelect: 'none',
    },
    cursor: 'default',
  },
])
