/* Copyright 2013 - 2022 Waiterio LLC */
/** @jsx jsx */
import React, { useState } from 'react'
import { css, jsx } from '@emotion/react'

const menuContainer = css({
  cursor: 'pointer',
  WebkitTapHighlightColor: 'transparent',
  padding: '0px 16px',
  width: 64,
  height: 64,
  display: 'flex',
  justifyContent: 'center',
})

const menuIcon = expanded =>
  css({
    width: 24,
    position: 'absolute',
    top: '40%',

    ':after, :before': {
      content: "''",
      width: 24,
      height: 2,
      position: 'absolute',
      backgroundColor: 'white',
      transition: '.3s ease-in-out',
      top: expanded ? 5 : 0,
      transform: expanded ? 'rotate(45deg)' : 0,
    },

    ':before': {
      top: expanded ? 5 : 10,
      transform: expanded ? 'rotate(-45deg)' : 0,
    },
  })

const links = expanded =>
  css({
    overflow: 'hidden',
    height: `${expanded ? '100%' : '0px'}`,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: 'var(--color-primary)',
    position: 'fixed',
    top: 64,
    left: 0,
    zIndex: 1000,
    transition: 'height 0.4s ease-in-out',
  })

const NavBarSmartphoneMenu = ({ children }) => {
  const [menu, setMenu] = useState(false)

  const toggleMenu = () => {
    setMenu(menu => !menu)
  }

  return (
    <>
      <div css={menuContainer} onClick={toggleMenu}>
        <div css={menuIcon(menu)} data-testid="icon-menu" />
      </div>

      <div css={links(menu)}>{children}</div>
    </>
  )
}

export default NavBarSmartphoneMenu
