/* Copyright 2013 - 2022 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { Link } from 'react-router-dom'

export const link = css`
  color: white;
  display: inline-block;
  font-size: 16px;
  line-height: 48px;
  height: 48px;
  padding: 0px 16px;
  position: relative;
  :hover {
    color: var(--color-secondary-light);
  }
  :active {
    color: var(--color-secondary-dark);
  }
  @media (max-width: 1199px) {
    padding: 0 10px;
  }
`

const NavBarLink = ({ label, to, href, style }) => {
  if (href) {
    return (
      <a css={[link, style]} href={href}>
        {label}
      </a>
    )
  } else {
    return (
      <Link css={[link, style]} to={to}>
        {label}
      </Link>
    )
  }
}

export default NavBarLink
