/* Copyright 2013 - 2022 Waiterio LLC */
export default function getCurrencyNameForCurrenciesWithRedundantSymbols(currencyCode) {

  switch (currencyCode) {

    case 'ALL': return 'Albanian Leks'
    case 'ARS': return 'Pesos Argentino'
    case 'AUD': return 'Australian Dollars'
    case 'BBD': return 'Barbadian Dollars'
    case 'BGN': return 'Bulgarian lev'
    case 'BMD': return 'Bermudian Dollars'
    case 'BND': return 'Brunei Dollars'
    case 'BSD': return 'Bahamian Dollars'
    case 'BWP': return 'Botswana Pula'
    case 'CAD': return 'Canadian Dollars'
    case 'CLP': return 'Pesos Chileno'
    case 'CNY': return '人民币'
    case 'COP': return 'Pesos Colombiano'
    case 'CUP': return 'Cuban Pesos'
    case 'CVE': return 'Escudos Cabo-Verdiano'
    case 'DKK': return 'Danske Kroner'
    case 'EGP': return 'جنيه مصر'
    case 'HNL': return 'Honduran Lempiras'
    case 'IRR': return 'Iranian Rials'
    case 'ISK': return 'Icelandic Kronas'
    case 'JPY': return '日本円'
    case 'KGS': return 'Kyrgyzstani Som'
    case 'LKR': return 'Sri Lankan Rupees'
    case 'MOP': return 'Macanese pataca'
    case 'MRU': return 'Mauritian rupee'
    case 'MXN': return 'Pesos MEXICANO'
    case 'NAD': return 'Namibian Dollars'
    case 'NOK': return 'Norske Kroner'
    case 'NZD': return 'New Zealand Dollars'
    case 'PHP': return 'Philippine Pesos'
    case 'PKR': return 'Pakistani Rupees'
    case 'RWF': return 'Rwandan Francs'
    case 'SEK': return 'Svenske Kroner'
    case 'SGD': return 'Singapore Dollars'
    case 'SHP': return 'Saint Helena Pounds'
    case 'SRD': return 'Surinaamse Dollars'
    case 'SSP': return 'South Sudan Pounds'
    case 'SYP': return 'Syrian Pounds'
    case 'SZL': return 'Swazi Lilangeni'
    case 'TMT': return 'Turkmen Manat'
    case 'TTD': return 'Trinidad and Tobago Dollars'
    case 'USD': return 'US Dollars'
    case 'UYU': return 'Pesos Uruguayo'
    case 'WST': return 'Samoan Tala'
    case 'XAF': return 'Central African CFA Francs'
    case 'XCD': return 'Caribbean Dollars'
    case 'XOF': return 'West African CFA Francs'
    case 'YER': return 'Yemeni Rials'
    case 'ZWL': return 'Zimbabwean Dollars'

    default: return null

  }

}
