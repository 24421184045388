/* Copyright 2013 - 2022 Waiterio LLC */
import React from 'react'
import { css, Global } from '@emotion/react'
import defaults from '@stiloso/styles/defaults.js'
import generateTheme from '@stiloso/styles/theme/generateTheme.js'

export default colors => {
  let theme = generateTheme(colors)

  const themeCssVariables = Object.keys(theme).reduce((string, themeKey) => {
    const themeValue = theme[themeKey]

    string += `${themeKey}: ${themeValue};`

    return string
  }, '')

  const styles = css`
    :root {
      ${themeCssVariables}
    }

    ${defaults}
  `

  return <Global styles={styles} />
}
