/* Copyright 2013 - 2022 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { Link } from 'react-router-dom'

const navbarSmartphoneButton = css`
  flex-wrap: wrap;
  flex: 0 1 auto;
  display: flex;
  justify-content: center;
  margin: 20px;
  -webkit-tap-highlight-color: transparent;
  text-align: center;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  color: white;
  background-color: var(--color-secondary);
  padding: 14px;
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:not(.disabled):active {
    background-color: var(--color-secondary-dark);
  }
`

const NavBarSmartphoneButton = ({ label, to, href }) => {
  if (href) {
    return (
      <a css={navbarSmartphoneButton} href={href}>
        {label}
      </a>
    )
  } else {
    return (
      <Link css={navbarSmartphoneButton} to={to}>
        {label}
      </Link>
    )
  }
}

export default NavBarSmartphoneButton
