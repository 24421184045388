/* Copyright 2013 - 2022 Waiterio LLC */
/** @jsx jsx */
import { jsx } from '@emotion/react'

import { useTranslation } from '@multilocale/react/index.js'

import NavBar from './NavBar.js'
import NavBarButton from './NavBarButton.js'
import NavBarFiller from './NavBarFiller.js'
import NavBarLink from './NavBarLink.js'

const NavigationDesktopComponent = ({ locale, AppIcon, appUrl, appName }) => {
  const { t } = useTranslation(locale)

  return (
    <NavBar>
      <a aria-label={appName} href="/">
        {AppIcon}
      </a>
      <NavBarFiller />
      <NavBarLink label={t('Blog')} href="/blog" />
      <NavBarLink label={t('Pricing')} href={`/${locale}/pricing`} />
      <NavBarLink label={t('Login')} href={`${appUrl}/login`} />
      <NavBarButton label={t('Try it for free')} href={`${appUrl}/signup`} />
    </NavBar>
  )
}

export default NavigationDesktopComponent
