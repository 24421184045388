/* Copyright 2013 - 2022 Waiterio LLC */
export default function () {

  if (document && document.domain) {

    let levels = (document.domain + '').split('.')

    if (document.domain === 'localhost' || document.domain.indexOf('192.168') !== -1 || document.domain.indexOf('10.0.2.2') !== -1 || document.domain.indexOf('127.0.0.1') !== -1) {
      // DO NOTHING ON PURPOSE
    } else if (levels.length >= 3) {
      document.domain = levels[levels.length - 3] + '.' + levels[levels.length - 2] + '.' + levels[levels.length - 1]
    }

  }

}
