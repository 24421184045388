/* Copyright 2013 - 2022 Waiterio LLC */
import { css } from '@emotion/react'

export default css`
  label: col-lg-4;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  @media (min-width: 992px) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
`
