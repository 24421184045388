/* Copyright 2013 - 2022 Waiterio LLC */
import React, { use } from 'react'
import { useRoutes } from 'react-router-dom'
import getRoutes from 'tagliatelle/getRoutes.js'
import context from './webpackContext.mjs'

const Routes = () => {
  let routes = use(getRoutes({ context }))
  return useRoutes(routes)
}

export default Routes
