/* Copyright 2013 - 2022 Waiterio LLC */
export default {

  AED: 'د.إ',
  AFN: '؋',
  ALL: 'L',
  AMD: 'դր.',
  AOA: 'Kz',
  ARS: '$',
  AUD: '$',
  AWG: 'Afl',
  AZN: '₼',
  BAM: 'KM',
  BBD: '$',
  BDT: '৳',
  BGN: 'лв',
  BHD: 'BD',
  BIF: 'FBu',
  BMD: '$',
  BND: 'B$',
  BOB: '$b',
  BRL: 'R$',
  BSD: 'B$',
  BWP: 'P',
  BYN: 'Br',
  BZD: 'BZ$',
  CAD: '$',
  CDF: 'FC',
  CHF: 'Fr.',
  CLP: '$',
  CNY: '¥',
  COP: '$',
  CRC: '₡',
  CUP: '₱',
  CVE: '$',
  CZK: 'Kč',
  DJF: 'Fdj',
  DKK: 'kr',
  DOP: 'RD$',
  DZD: 'DA',
  EGP: '£',
  EUR: '€',
  GEL: 'ლ',
  GBP: '£',
  GMD: 'D',
  GNF: 'FG',
  GTQ: 'Q',
  HKD: 'HK$',
  HNL: 'L',
  HRK: 'kn',
  HTG: 'G',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  INR: '₹',
  IQD: 'د.ع',
  IRR: '﷼',
  ISK: 'kr',
  JMD: 'J$',
  JOD: 'JD',
  JPY: '¥',
  KES: 'KSh',
  KGS: 'лв',
  KHR: '៛',
  KMF: 'CF',
  KRW: '₩',
  KWD: 'ك',
  KYD: 'CI$',
  KZT: '₸',
  LAK: '₭',
  LBP: 'ل.ل.‎',
  LKR: 'Rs',
  LYD: 'LD',
  MAD: 'د.م.',
  MGA: 'Ar',
  MKD: 'ден',
  MMK: 'K',
  MNT: '₮',
  MOP: 'P',
  MUR: 'Rs',
  MRU: 'UM',
  MVR: 'MVR',
  MWK: 'MK',
  MXN: '$',
  NGN: '₦',
  NPR: 'रु',
  MYR: 'RM',
  NAD: '$',
  NIO: 'C$',
  NOK: 'kr',
  NZD: '$',
  OMR: 'ر.ع.',
  PAB: 'B/.',
  PEN: 'S/.',
  PHP: '₱',
  PKR: 'Rs',
  PLN: 'zł',
  PYG: '₲',
  QAR: 'ر.ق',
  RON: 'lei',
  RSD: 'РСД',
  RUB: '₽',
  RWF: 'Fr',
  SAR: 'ر.س',
  SBD: 'SI$',
  SCR: '₨',
  SDG: 'SD',
  SEK: 'kr',
  SGD: '$',
  SHP: '£',
  SOS: 'Sh',
  SRD: '$',
  SSP: '£',
  STD: 'Db',
  SYP: '£',
  SZL: 'L',
  THB: '฿',
  TJS: 'C',
  TMT: 'T',
  TND: 'د.ت',
  TOP: 'T$',
  TRY: '₤',
  TTD: '$',
  TWD: 'NT$',
  TZS: 'TSh',
  UAH: '₴',
  UGX: 'USh',
  USD: '$',
  UYU: '$',
  UZS: 'soʻm',
  VEF: 'Bs',
  VND: '₫',
  VUV: 'VT',
  WST: 'T',
  XAF: 'Fr',
  XCD: '$',
  XOF: 'Fr',
  XPF: 'CFP',
  YER: '﷼',
  ZAR: 'R',
  ZMW: 'ZK',
  ZWL: '$',

}
