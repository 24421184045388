/* Copyright 2013 - 2022 Waiterio LLC */
/** @jsx jsx */
import React, { Component } from 'react'
import { jsx } from '@emotion/react'

export default class ErrorBoundaryGlobal extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, info) {
    const { onCatchError } = this.props

    if (onCatchError) {
      onCatchError(error)
    }

    this.setState({
      hasError: true,
      error,
      info,
    })
  }

  render() {
    const { hasError, error, info } = this.state

    const url = new URL(window.location.href)
    const again = url.searchParams.get('again')

    if (hasError) {
      return (
        <div css={{ padding: 16 }}>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              margin: '0 auto',
              maxWidth: 500,
            }}
          >
            {again ? (
              <h1 css={{ textAlign: 'center' }}>Oops, I did it again</h1>
            ) : (
              <h1 css={{ textAlign: 'center' }}>Oops, something went wrong</h1>
            )}
            <p>
              The error:
              <br />
              {error.toString()}
            </p>
            <p>
              Where it occured:
              <br />
              {info.componentStack}
            </p>
            {again ? (
              <form action="/logout">
                <button
                  type="submit"
                  css={{
                    width: '100%',
                    lineHeight: '48px',
                    textAlign: 'center',
                    marginTop: 8,
                    textTransform: 'uppercase',
                    cursor: 'pointer',
                    backgroundColor: 'var(--color-primary, DodgerBlue)',
                    color: 'white',
                    fontSize: 20,
                    fontWeight: 'bold',
                  }}
                >
                  Logout
                </button>
              </form>
            ) : (
              <form>
                <input type="hidden" name="again" value="true" />
                <button
                  type="submit"
                  css={{
                    width: '100%',
                    lineHeight: '48px',
                    textAlign: 'center',
                    marginTop: 8,
                    textTransform: 'uppercase',
                    cursor: 'pointer',
                    backgroundColor: 'var(--color-green, LimeGreen)',
                    color: 'white',
                    fontSize: 20,
                    fontWeight: 'bold',
                  }}
                >
                  Try again
                </button>
              </form>
            )}
          </div>
        </div>
      )
    }

    return this.props.children
  }
}
