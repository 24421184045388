/* Copyright 2013 - 2022 Waiterio LLC */
export default [
  'en',
  'es',
  'de',
  'pt',
  'it',
  'fr',
  'ru',
  'nl',
  'th',
  'el',
  'zh',
  'id',
  'tr',
  'pl',
  'ko',
  'hu',
  'ro',
  'hr',
  'vi',
  'sr',
  'ar',
  'da',
  'sv',
  'ca',
  'uk',
  'ja',
  'cs',
]
