/* Copyright 2013 - 2022 Waiterio LLC */
import React from 'react'
import { Navigate } from 'react-router-dom'
import getBrowserLanguage from '@monorepo/shared/getBrowserLanguage.js'

export const paths = ['/pricing/']

const PricingRedirect = () => {
  const locale = getBrowserLanguage() || 'en'
  return <Navigate exact to={`/${locale}/pricing/`} />
}

export default PricingRedirect
