/* Copyright 2013 - 2022 Waiterio LLC */
import get from './get.js'

export default function getPhrases(parameters) {
  let url = 'phrases'

  url += '?' + new URLSearchParams(parameters).toString()

  return get({ url, retries: 3 }).catch(error => {
    throw new Error(`Couldn't get phrases \n${error}`)
  })
}
