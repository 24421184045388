/* Copyright 2013 - 2022 Waiterio LLC */
import { css } from '@emotion/react'

const androidToaster = css`
  #android_toast_container {
    position: fixed;
    width: 100%;
    bottom: 12%;
  }

  #android_toast {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #303437;
    color: #f5f5f5;
    font-size: 18px;
    padding: 5px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    opacity: 0.95;
    -webkit-box-shadow: 1px 2px 2px 1px rgba(34, 34, 34, 1);
    box-shadow: 1px 2px 2px 1px rgba(34, 34, 34, 1);
    text-align: center;
  }

  #android_toast_em {
    color: #51b4d2;
    font-weight: 700;
    font-style: normal;
  }

  .android_toast_top {
    top: 12%;
    bottom: auto !important;
  }

  .android_toast_fadein {
    animation: android_toast_fadein 1s ease;
    -webkit-animation: android_toast_fadein 1s ease;
  }

  .android_toast_fadeout {
    animation: android_toast_fadeout 1s ease;
    -webkit-animation: android_toast_fadeout 1s ease;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
  }

  @keyframes android_toast_fadein {
    from {
      opacity: 0;
      -webkit-opacity: 0;
    }

    to {
      opacity: 0.95;
      -webkit-opacity: 0.95;
    }
  }

  @-webkit-keyframes android_toast_fadein {
    from {
      opacity: 0;
      -webkit-opacity: 0;
    }

    to {
      opacity: 0.95;
      -webkit-opacity: 0.95;
    }
  }

  @keyframes android_toast_fadeout {
    from {
      opacity: 0.95;
      -webkit-opacity: 0.95;
    }

    to {
      opacity: 0;
      -webkit-opacity: 0;
    }
  }

  @-webkit-keyframes android_toast_fadeout {
    from {
      opacity: 0.95;
      -webkit-opacity: 0.95;
    }

    to {
      opacity: 0;
      -webkit-opacity: 0;
    }
  }
`

export default androidToaster
