/* Copyright 2013 - 2022 Waiterio LLC */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import container from '@stiloso/styles/bootstrap/container.js'
import row from '@stiloso/styles/bootstrap/row.js'
import colLg4 from '@stiloso/styles/bootstrap/colLg4.js'
import colMd6 from '@stiloso/styles/bootstrap/colMd6.js'
import colLg6 from '@stiloso/styles/bootstrap/colLg6.js'
import colSm12 from '@stiloso/styles/bootstrap/colSm12.js'

const section = css`
  padding: 80px 0px;
  background-color: white;

  @media (max-width: 992px) {
    padding: 64px 0px;
  }

  @media (max-width: 768px) {
    padding: 48px 0px;
  }
`

const sectionHeading = css`
  font-size: 36px;
  text-transform: capitalize;
  font-weight: bold;
  text-align: center;
  color: var(--color-primary);

  @media (max-width: 992px) {
    font-size: 28px;
    line-height: 32px;
  }
`

const subsectionItem = css`
  ${colSm12}
  ${colMd6}
  ${colLg4}
 
 margin: 8px 0px;
`

const decorativeImageContainer = css`
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const illustrationStyle = css`
  aspect-ratio: 3/2;
  width: 360px;

  @media (max-width: 1200px) {
    width: 240px;
  }
`

const subsectionHeading = css`
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  color: var(--color-primary);

  @media (max-width: 992px) {
    font-size: 20px;
  }
`

const subsectionContent = css`
  line-height: 24px;
  color: #6a768a;
  font-size: 16px;
`

export default function HowSection({ title, subsections }) {
  return (
    <section id="how-to-get-started-section" css={section}>
      <div css={container}>
        <div css={[row, { justifyContent: 'center' }]}>
          <div css={[colMd6, colLg6]}>
            <h2 css={sectionHeading}>{title}</h2>
          </div>
        </div>
        <div css={[row, { marginTop: 48 }]}>
          {subsections.map(({ title, content, Illustration }, index) => (
            <div key={title || index} css={subsectionItem}>
              <div css={decorativeImageContainer}>
                <Illustration css={illustrationStyle} />
              </div>
              <h3 css={subsectionHeading}>
                {index + 1}. {title}
              </h3>
              <p css={subsectionContent}>{content}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
