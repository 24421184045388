/* Copyright 2013 - 2022 Waiterio LLC */
import React from 'react'

const IconTranslate = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-translate'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 48 48"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="m23.75 45.15 9.6-25.4h4.3l9.85 25.4h-4.6l-2.15-6.7H30.6l-2.5 6.7Zm8-10.4h7.5l-3.7-10.25h-.1ZM7.05 38.8 4.2 35.9 15 25.1q-2.05-2.3-3.6-4.675-1.55-2.375-2.7-4.875h4.6q.85 1.75 1.95 3.3 1.1 1.55 2.45 3.2 2.4-2.5 4-5.175t2.75-5.575H.75V7.05h14.8v-4.2h4.25v4.2h14.75v4.25h-5.9q-1.2 3.65-3.15 7.175t-4.75 6.675l5.2 5.25-1.5 4.25-6.8-6.45Z" />
    </svg>
  )
}

export default IconTranslate
